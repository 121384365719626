import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faSave,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons';
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from 'react';
import {
  Card,
  Form,
  Button,
  ButtonGroup,
  InputGroup,
  Modal,
  Spinner,
  Col,
  Row,
  Image,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { updateRosterLine, addRosterLine } from '../../utils/rosterLineCalls';
import RingInputSection from '../RingInputSection/RingInputSection';
import PackageComparisonTable from '../PackageComparisonTable/PackageComparisonTable';
import basicPackageImage from '../../assets/images/basic_icon.jpg';
import championsPackageImage from '../../assets/images/Champions_PKG_Layout.png';
import dynastyPackageImage from '../../assets/images/Dynasty_PKG_Layout.png';
import ultimatePackageImage from '../../assets/images/Ultimate_PKG_Layout.png';
import nameplateImage from '../../assets/images/Nameplate_Customizable_Text.jpg';
import championsPlusPackageImage from '../../assets/images/Champions+ package.jpg';
import championsPlusPackageNameplateImage from'../../assets/images/ChampionsPlus_Nameplate_Customization.png';


import getStates from '../../utils/getState';
import './RingSelectionView.css';
import getCountries from '../../utils/getCountry';
import { UserContext } from '../../contexts/UserContext';

const RingSelectionView = ({
  rosterKey,
  roster,
  playerData,
  closeModal,
  cancelEdit,
  updatePlayer,
  addPlayer,
  individualSelection,
  selectedPlayers,
  setSelectedPlayers,
  playerSelectCount,
  setPlayerSelectCount,
  internalView,
  adminUser,
}) => {
  const roleHidenMaxLength = ['SalesManager', 'Verifier'];
  const { user } = useContext(UserContext);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showPackageModal, setShowPackageModal] = useState(false);
  const [working, setWorking] = useState(false);
  const [editMode, setEditMode] = useState(
    playerData && playerData.id && playerData.id !== ''
  );
  const [initialStateSaved, setInitialStateSaved] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(
    playerData ? playerData.masterOrderItemId : ''
  );
  const [productList, setProductList] = useState([
    { title: 'Select a Product', masterOrderItemId: '' },
  ]);
  const [product, setProduct] = useState('');
  const [itemId, setItemId] = useState('');
  const [productType, setProductType] = useState('');
  const [packageProductType, setPackageProductType] = useState(false);
  const [packageHasNameplate, setPackageHasNameplate] = useState(false);
  const [packageHasAccessories, setPackageHasAccessories] = useState(false);
  const [basicPackageAvailable, setBasicPackageAvailable] = useState(false);
  const [championsPackageAvailable, setChampionsPackageAvailable] =
    useState(false);
  const [dynastyPackageAvailable, setDynastyPackageAvailable] = useState(false);
  const [ultimatePackageAvailable, setUltimatePackageAvailable] =
    useState(false);
  const [championsPlusPackageAvailable, setChamionsPlusPackageAvailable] =
    useState(false);
  const [selectedNameplateImage, setSelectedNameplateImage] = useState('');
  const [jerseyNumberPendantOptions, setJerseyNumberPendantOptions] = useState(
    []
  );
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltipLastName, setShowTooltipLastName] = useState(false);
  const [valueFirstName, setValueFirstName] = useState(
    playerData.package &&
      'package' in playerData &&
      playerData.package.customizations &&
      'customizations' in playerData.package &&
      playerData.package.customizations.length > 0
      ? playerData.package.customizations[1].text
      : ''
  );
  const [valueLastName, setValueLastName] = useState(
    playerData.package &&
      'package' in playerData &&
      playerData.package.customizations &&
      'customizations' in playerData.package &&
      playerData.package.customizations.length > 0
      ? playerData.package.customizations[1].text
      : ''
  );
  const inputRef = useRef(null);
  const [isShowMaxLength, setIsShowMaxLength] = useState(true);

  const handleInputClick = (value, fieldName) => {
    if (fieldName == 'First Name') {
      setShowTooltip(true);
    } else {
      setShowTooltipLastName(true);
    }
    if (value.length >= 25) {
      if (fieldName == 'First Name') {
        setShowTooltip(true);
      } else {
        setShowTooltipLastName(true);
      }
      setTimeout(() => {
        if (fieldName == 'First Name') {
          setShowTooltip(false);
        } else {
          setShowTooltipLastName(false);
        }
      }, 2000);
    }
  };

  const checkAvailablePackages = () => {
    if (
      roster.packages.filter((ringPackage) =>
        ringPackage.title.includes('Ring Only')
      ).length > 0 ||
      roster.packages.filter((ringPackage) =>
        ringPackage.title.includes('Basic')
      ).length > 0
    ) {
      setBasicPackageAvailable(true);
    }

    if (
      roster.packages.filter((ringPackage) =>
        ringPackage.title.includes('Champions') && !ringPackage.title.includes('Champions+')
      ).length > 0
    ) {
      setChampionsPackageAvailable(true);
    }

    if (
      roster.packages.filter((ringPackage) =>
        ringPackage.title.includes('Dynasty')
      ).length > 0
    ) {
      setDynastyPackageAvailable(true);
    }

    if (
      roster.packages.filter((ringPackage) =>
        ringPackage.title.includes('Ultimate')
      ).length > 0
    ) {
      setUltimatePackageAvailable(true);
    }

    if (
      roster.packages.filter((ringPackage) =>
        ringPackage.title.includes('Champions+')
      ).length > 0
    ) {
      setChamionsPlusPackageAvailable(true);
    }
  };

  const generateJerseyNumberPendantOptions = () => {
    let numberOptions = [];
    let outOfStockOptions = [];
    numberOptions.push('00');
    for (let i = 0; i < 100; i++) {
      if (!outOfStockOptions.includes(i)) {
        numberOptions.push(i);
      }
    }
    setJerseyNumberPendantOptions(numberOptions);
  };

  useEffect(() => {
    const fetchData = async () => {
      const countries = await getCountries();
      setCountries(countries);

      const data = await getStates('US');
      setStates(data);
    };

    fetchData();

    if (user != null) {
      const isHiden = roleHidenMaxLength.some((r) => user.roles.includes(r));
      setIsShowMaxLength(!isHiden);
    }
  }, []);

  useEffect(() => {
    checkAvailablePackages();
    generateJerseyNumberPendantOptions();
  }, [roster]);

  const [formValues, setFormValues] = useState({
    id: playerData ? playerData.id : '',
    rosterId: playerData ? playerData.rosterId : roster.id,
    masterOrderItemId: playerData ? playerData.masterOrderItemId : '',
    subOrderItemRowId: playerData ? playerData.subOrderItemRowId : '',
    variation: playerData ? playerData.variation : '',
    variationId: playerData ? playerData.variationId : '',
    studentName: playerData ? playerData.studentName : '',
    studentEmail: playerData ? playerData.studentEmail : '',
    studentPhone: playerData ? playerData.studentPhone : '',
    addressLine1: playerData ? playerData.addressLine1 : '',
    addressLine2: playerData ? playerData.addressLine2 : '',
    city: playerData ? playerData.city : '',
    countryCode: 'US',
    state: playerData ? playerData.state : '',
    postalCode: playerData ? playerData.postalCode : '',
    fingerSize: playerData ? playerData.fingerSize : '',
    customizations: [],
    editable: true,
    productType: playerData ? playerData.productType : '',
    package: playerData && playerData.package ? playerData.package : null,
    optionalEngraving: playerData.optionalEngraving ?? false,
  });

  const [savedFormValues, setSavedFormValues] = useState({});

  const setProductObject = useCallback(() => {
    const newProduct = roster.products.filter(
      (product) => product.masterOrderItemId === selectedProduct
    );
    setProduct(newProduct[0]);
    setItemId(newProduct[0].masterOrderItemId);
    setProductType(newProduct[0].productType);
  }, [roster.products, selectedProduct]);

  useEffect(() => {
    if (roster.products) {
      setProductList(productList.concat(roster.products));

      if (roster.products.length === 1) {
        if (roster.accessLevel === 'Coach') {
          handleBatchInputChange(
            selectedPlayers,
            'masterOrderItemId',
            roster.products[0].masterOrderItemId
          );
        } else {
          handleInputChange(
            'masterOrderItemId',
            roster.products[0].masterOrderItemId
          );
        }
        setItemId(roster.products[0].masterOrderItemId);
        setSelectedProduct(roster.products[0].masterOrderItemId);
      }
    }
  }, [roster.products]);

  useEffect(() => {
    if (selectedProduct) {
      setProductObject();
    }
    return () => {};
  }, [selectedProduct, setProductObject]);

  useEffect(() => {
    if (playerData) {
      setEditMode(playerData && playerData.id && playerData.id !== '');
      if (formValues.customizations) {
        const newCustomObject = {
          id: playerData.id,
          customizations: formValues.customizations.concat(
            playerData.customizations
          ),
        };
        setFormValues({
          ...formValues,
          ...newCustomObject,
        });
      }
    }
  }, [playerData]);

  useEffect(() => {
    if (editMode && !initialStateSaved) {
      setSavedFormValues({ ...formValues });
      setInitialStateSaved(true);
    }
  }, [editMode, initialStateSaved]);

  useEffect(() => {
    if (
      productType === 'Championship Ring' ||
      productType === 'Fashion Ring' ||
      productType === 'Class Ring'
    ) {
      setPackageProductType(true);
    } else {
      setPackageProductType(false);
    }
  }, [productType]);


 useEffect(() => {
    if(formValues.package !== null)
    {
      if(formValues.package.name === 'Champions+')
      {
        setSelectedNameplateImage(championsPlusPackageNameplateImage)
      }
      else
      {
        setSelectedNameplateImage(nameplateImage);
      }
    }
  })
  const handleInputChange = (
    inputName,
    value,
    customizations,
    customizationValues
  ) => {
    if (inputName === 'package') {
      if (
        value.name === 'Dynasty' ||
        value.name === 'Ultimate' ||
        value.name === 'Champions+'
      ) {
        if(value.name === 'Champions+')
        {
          setSelectedNameplateImage(championsPlusPackageNameplateImage);
        }
        else{
          setSelectedNameplateImage(nameplateImage);
        }
        
        if (document.getElementById('nameplateFirstName')) {
          document.getElementById('nameplateFirstName').value = '';
          document.getElementById('nameplateLastName').value = '';
          if (
            document.getElementById('nameplateFirstName').value !== '' &&
            document.getElementById('nameplateFirstName').value !== null &&
            document.getElementById('nameplateLastName').value !== '' &&
            document.getElementById('nameplateLastName').value !== null
          ) {
            if (document.getElementById('add')) {
              document.getElementById('add').removeAttribute('disabled');
            }
            if (document.getElementById('update')) {
              document.getElementById('update').removeAttribute('disabled');
            }
          } else {
            if (document.getElementById('add')) {
              document.getElementById('add').disabled = true;
            }

            if (document.getElementById('update')) {
              document.getElementById('update').disabled = true;
            }
          }
        } else {
          if (document.getElementById('add')) {
            document.getElementById('add').disabled = true;
          }
          if (document.getElementById('update')) {
            document.getElementById('update').disabled = true;
          }
        }
      } else {
        if (document.getElementById('add')) {
          document.getElementById('add').removeAttribute('disabled');
        }
        if (document.getElementById('update')) {
          document.getElementById('update').removeAttribute('disabled');
        }
      }
    } else if (inputName === 'countryCode') {
      const fetchData = async () => {
        const data = await getStates(value);
        setStates(data);
      };
      if (value === '') {
        setStates([]);
      } else {
        fetchData();
      }
    }

    if (inputName === 'masterOrderItemId') {
      const newProduct = roster.products.find(
        (product) => product.masterOrderItemId === value
      );

      let writtenName = '';
      let htmlName = '';
      let newPackageValue = null;
      setPackageHasNameplate(false);
      setPackageHasAccessories(false);

      product.customizations?.forEach((customization) => {
        writtenName = customization.name;
        htmlName = convertCustomizationName(writtenName);
        if (document.getElementsByName(htmlName)[0]) {
          console.log(
            htmlName + ': ' + document.getElementsByName(htmlName)[0].value
          );
          document.getElementsByName(htmlName)[0].value = '';
        }
        if (document.getElementsByName(htmlName)[1]) {
          console.log(
            htmlName + ' ' + document.getElementsByName(htmlName)[1].value
          );
          document.getElementsByName(htmlName)[1].value = '';
        }
      });
      newProduct.customizations.forEach((customization) => {
        writtenName = customization.name;
        htmlName = convertCustomizationName(writtenName);

        if (document.getElementsByName(htmlName)[0]) {
          document.getElementsByName(htmlName)[0].value = '';
        }
        if (document.getElementsByName(htmlName)[1]) {
          document.getElementsByName(htmlName)[1].value = '';
        }
      });

      if (newProduct.variations.length === 1) {
        setFormValues({
          ...formValues,
          masterOrderItemId: value,
          variationId: newProduct.variations[0].variationId,
          [customizations]: customizationValues,
          productType: productType,
          package: newPackageValue,
        });
      } else {
        setFormValues({
          ...formValues,
          masterOrderItemId: value,
          [customizations]: customizationValues,
          productType: productType,
          package: newPackageValue,
        });
      }
    } else {
      setFormValues({
        ...formValues,
        [inputName]: value,
        //Resets customization values - only on product selection change
        [customizations]: customizationValues,
        productType: productType,
      });
    }

    checkFormValidation();
  };
  const convertCustomizationName = (asWrittenName) => {
    let nameWords = asWrittenName.split(' ');
    let htmlName = '';
    nameWords.forEach((word, idx) => {
      if (idx > 0) {
        htmlName += word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        htmlName += word.toLowerCase();
      }
    });
    return htmlName;
  };
  const initalEngraving = () => {
    if (
      playerData.package.customizations[0].text === '' ||
      playerData.package.customizations[0].text === null ||
      playerData.package.customizations[1].text === '' ||
      playerData.package.customizations[1].text === null
    ) {
      if (document.getElementById('add')) {
        document.getElementById('add').disabled = true;
      }
      if (document.getElementById('update')) {
        document.getElementById('update').disabled = true;
      }
    }
  };

  const handlePackageValidation = () => {
    if (
      document.getElementById('nameplateFirstName').value === '' ||
      document.getElementById('nameplateFirstName').value === null ||
      document.getElementById('nameplateLastName').value === '' ||
      document.getElementById('nameplateLastName').value === null
    ) {
      if (document.getElementById('add')) {
        document.getElementById('add').disabled = true;
      }
      if (document.getElementById('update')) {
        document.getElementById('update').disabled = true;
      }
    } else {
      if (document.getElementById('add')) {
        document.getElementById('add').removeAttribute('disabled');
      }
      if (document.getElementById('update')) {
        document.getElementById('update').removeAttribute('disabled');
      }
    }
  };

  const handleBatchInputChange = (selectedRecords, inputName, value) => {
    checkFormValidation();
    selectedRecords.map((record) =>
      setFormValues({
        ...formValues,
        [inputName]: value,
        productType: productType,
      })
    );
  };

  const handleCustomInputChange = (inputName, value) => {
    checkFormValidation();
    const customValues = product.customizations;
    const filteredCustomValue = customValues.filter((customValue) => {
      return customValue.name.toLowerCase() === inputName.toLowerCase();
    });
    if (filteredCustomValue.length > 0) {
      const newCustomValue = {
        ...filteredCustomValue[0],
        text: value.toUpperCase(),
        name: filteredCustomValue[0].name,
      };
      let filterFormCustomValue = [];
      if (formValues.customizations.length > 0) {
        filterFormCustomValue = formValues.customizations.filter(
          (custom) => custom.name !== newCustomValue.name
        );
      }
      filterFormCustomValue.push(newCustomValue);
      setFormValues({ ...formValues, customizations: filterFormCustomValue });
    }
  };

  const handleCustomInputInsideEngravingChange = (inputNameList, value) => {
    checkFormValidation();
    const customValues = product.customizations;
    let filterFormCustomValue = formValues.customizations;
    inputNameList.map((inputName) => {
      const filteredCustomValue = customValues.filter((customValue) => {
        return customValue.name.toLowerCase() === inputName.toLowerCase();
      });
      if (filteredCustomValue.length > 0) {
        const newCustomValue = {
          ...filteredCustomValue[0],
          text: value.toUpperCase(),
          name: filteredCustomValue[0].name,
        };
        let indexCustomizations = filterFormCustomValue.findIndex(
          (custom) => custom.name === newCustomValue.name
        );
        if (indexCustomizations > -1) {
          filterFormCustomValue[indexCustomizations] = newCustomValue;
        }
      }
    });
    setFormValues({ ...formValues, customizations: filterFormCustomValue });
  };

  const handlePackageInputChange = (inputName, value) => {
    checkFormValidation();
    let nameplateFirstName = 'First Name';
    let nameplateLastname = 'Last Name';
    let tShirtSize = 'T-Shirt Size';
    let jerseyNumberPendant = 'Jersey Number Pendant';
    if (inputName === 'nameplateFirstName') {
      inputName = nameplateFirstName;
    }
    if (inputName === 'nameplateLastName') {
      inputName = nameplateLastname;
    }
    if (inputName === 'tShirtSize') {
      inputName = tShirtSize;
    }
    if (inputName === 'jerseyNumberPendant') {
      inputName = jerseyNumberPendant;
    }
    const customValues = formValues.package.customizations;
    const filteredCustomValue = customValues.filter((customValue) => {
      return customValue.name.toLowerCase() === inputName.toLowerCase();
    });
    if (filteredCustomValue.length > 0) {
      const newCustomValue = {
        ...filteredCustomValue[0],
        name: filteredCustomValue[0].name,
        text: value.toUpperCase(),
      };
      filteredCustomValue[0].text = newCustomValue.text;
    }
  };

  const lookupVariationDescription = (masterOrderItemId, variationId) => {
    // Set the variation ID based on the variation description
    let product = roster.products.find(
      (product) => product.masterOrderItemId === masterOrderItemId
    );
    let variant = product.variations.find(
      (variant) => variant.variationId === variationId
    );
    if (variant == null) {
      variant = product.variations[0];
    }

    return variant.description;
  };

  const onCancelClick = () => {
    if (editMode) {
      cancelEdit();
    } else {
      clearForm();
    }
    closeModal && closeModal();
  };

  /*function removeDuplicates(arr)
  {
    return arr.filter(())
  }*/

  const onUpdateClick = (values) => {
    if (validForm === true) {
      values.variation = lookupVariationDescription(
        values.masterOrderItemId,
        values.variationId
      );
      if (roster.packages.length == 0) {
        values.package = null;
      }
      //values.name = values.name.replace("\t", " ");

      values.studentName = replaceTabs(values.studentName);
      values.studentPhone = replaceTabs(values.studentPhone);
      values.studentEmail = replaceTabs(values.studentEmail);
      values.addressLine1 = replaceTabs(values.addressLine1);
      values.addressLine2 = replaceTabs(values.addressLine2);
      values.city = replaceTabs(values.city);
      values.postalCode = replaceTabs(values.postalCode);
      if (values.customizations != null) {
        console.log(product);

        let customizationSortOrder = [];
        product.customizations.map((customization) => {
          console.log(customization);
          customizationSortOrder.push(customization.name);
        });
        customizationSortOrder.push(
          'Engraving Line 1',
          'Engraving line 2',
          'Engraving Line 3',
          'Finger Size',
          'Package'
        );

        console.log(customizationSortOrder);
        customizationSortOrder = customizationSortOrder.map((name) =>
          name.toLowerCase()
        );
        customizationSortOrder = customizationSortOrder.filter(
          (item, index) => customizationSortOrder.indexOf(item) === index
        );
        console.log(customizationSortOrder);
        let updatedCustomizations = [...values.customizations];
        console.log(values.customization);
        values.customizations.map(
          (customization, idx) => (
            console.log(customization),
            (customization.text = replaceTabs(customization.text))
          )
        );
        updatedCustomizations = updatedCustomizations.sort((a, b) => {
          const indexA = customizationSortOrder.indexOf(a.name.toLowerCase());
          console.log(indexA);
          const indexB = customizationSortOrder.indexOf(b.name.toLowerCase());
          console.log(indexB);
          return (
            (indexA === -1 ? Number.MAX_VALUE : indexA) -
            (indexB === -1 ? Number.MAX_VALUE : indexB)
          );
        });
        console.log(updatedCustomizations);
        values.customizations = updatedCustomizations;
      }
      if (values.package != null) {
        if (values.package.customizations != null) {
          values.package.customizations.map(
            (customization, idx) => (
              console.log(customization),
              (customization.text = replaceTabs(customization.text))
            )
          );
        }
      }

      updateRosterLine(rosterKey, values.id, values);
      updatePlayer(values);
      clearForm();
      document.getElementById('mainForm').reset();
      setValidationChecked(false);
      setValidForm(false);
      closeModal && closeModal();
    }
  };

  const replaceTabs = (fieldName) => {
    if (fieldName != null) {
      fieldName = fieldName.replace('\t', ' ');
    }
    return fieldName;
  };

  const onUpdatesClick = (values, variationId, variation, playerPackage) => {
    if (validForm === true) {
      values.map(
        (indValue, idx) => (
          (indValue.masterOrderItemId = product.masterOrderItemId),
          (indValue.variation = variation),
          (indValue.variation = lookupVariationDescription(
            values[idx].masterOrderItemId,
            variationId
          )),
          (indValue.package = playerPackage),
          updateRosterLine(rosterKey, indValue.id, indValue),
          updatePlayer(indValue)
        )
      );
      let noProductRows = document.getElementsByClassName('NoProduct');
      for (let i = 0; i < noProductRows.length; i++) {
        noProductRows[i].style.color = 'black';
        noProductRows[i].style.backgroundColor = '#f8f9fa';
      }
      playerSelectCount = 0;
      //setPlayerSelectCount(playerSelectCount);
      setPlayerSelectCount(0);
      document.getElementById('unselectAll').disabled = true;
      selectedPlayers.length = 0;
      clearForm();
      document.getElementById('mainForm').reset();
      setValidationChecked(false);
      setValidForm(false);
      closeModal && closeModal();
    }
  };

  const onSubmitClick = async (values) => {
    if (validForm === true) {
      // If the closeModal function was not passed in, then open the "adding" modal from this page
      if (!showAddModal) {
        setShowAddModal(true);
        // setWorking will turn the spinner on within the "adding" modal
        setWorking(true);
        document.getElementById('mainForm').style.display = 'none';
      }
      try {
        values.variation = lookupVariationDescription(
          values.masterOrderItemId,
          values.variationId
        );

        let response = await addRosterLine(rosterKey, values);
        values.id = response.data.id;

        values.studentName = replaceTabs(values.studentName);
        values.studentPhone = replaceTabs(values.studentPhone);
        values.studentEmail = replaceTabs(values.studentEmail);
        values.addressLine1 = replaceTabs(values.addressLine1);
        values.addressLine2 = replaceTabs(values.addressLine2);
        values.city = replaceTabs(values.city);
        values.postalCode = replaceTabs(values.postalCode);
        if (values.customizations != null) {
          values.customizations.map(
            (customization, idx) =>
              (customization.text = replaceTabs(customization.text))
          );
        }
        if (values.package != null) {
          if (values.package.customizations != null) {
            values.package.customizations.map(
              (customization, idx) => (
                console.log(customization),
                (customization.text = replaceTabs(customization.text))
              )
            );
          }
        }
        setTimeout(() => {
          setShowAddModal(false);
          document.getElementById('mainForm').style.display = 'block';
        }, 1500);
        setWorking(false);
        // If the addPlayer function was passed in then call it to add the player to the list displayed on the screen
        addPlayer && addPlayer(values);
        clearForm();
        document.getElementById('mainForm').reset();

        if (roster.products.length === 1) {
          if (roster.accessLevel === 'Coach') {
            handleBatchInputChange(
              selectedPlayers,
              'masterOrderItemId',
              roster.products[0].masterOrderItemId
            );
          } else {
            handleInputChange(
              'masterOrderItemId',
              roster.products[0].masterOrderItemId
            );
          }
          setItemId(roster.products[0].masterOrderItemId);
          setSelectedProduct(roster.products[0].masterOrderItemId);
          setFormValues({
            ...formValues,
            masterOrderItemId: roster.products[0].masterOrderItemId,
          });
        }
        setValidationChecked(false);
        setValidForm(false);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const clearForm = () => {
    let newFormValues = {
      id: '',
      rosterId: roster.id,
      masterOrderItemId: '',
      subOrderItemRowId: '',
      variation: '',
      variationId: '',
      studentName: '',
      studentEmail: '',
      customizations: [],
      editable: true,
      package: null,
      optionalEngraving: null,
    };
    setFormValues(newFormValues);
    setSelectedProduct('');
    setProduct('');
    setItemId('');
    setProductType('');
    setPackageHasNameplate(false);
    setPackageHasAccessories(false);
  };

  // New Form
  const [validationChecked, setValidationChecked] = useState(false);
  const [validForm, setValidForm] = useState(false);

  const checkFormValidation = () => {
    const form = document.getElementById('mainForm');

    if (document.getElementById('variationId')) {
      if (
        document.getElementById('variationId').value !== '' &&
        document.getElementById('variationId').value !== null
      ) {
        //playerData.variationId = '1';
      } else {
        if (playerData.variationId !== '' && playerData.variationId !== null) {
          document.getElementById('variationId').value = playerData.variationId;
        }
      }

      //playerData.variationId = document.getElementById('variationId').value;
    }
    if (document.getElementById('fingerSize')) {
      if (playerData.fingerSize !== '' && playerData.fingerSize != null) {
        document.getElementById('fingerSize').value = playerData.fingerSize;
      }
      /*else
      {
        if(document.getElementById('fingerSize').value !== '' && document.getElementById('fingerSize').value !== null){
        playerData.fingerSize = document.getElementById('fingerSize').value;
        }
      }*/
    }
    if (form.checkValidity() === true) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      setValidationChecked(true);
      setValidForm(false);
    } else {
      setValidationChecked(true);
      setValidForm(true);
    }
  };

  const checkPackageCustomizations = (pkg) => {
    setPackageHasNameplate(false);
    setPackageHasAccessories(false);
    if (pkg === 'Dynasty' || pkg === 'Ultimate' || pkg === 'Champions+') {
      setPackageHasNameplate(true);
    }
    if (pkg === 'Ultimate') {
      setPackageHasAccessories(true);
    }
  };

  const renderTooltip = () => (
    <Tooltip id="tooltip" className="custom-tooltip">
      <span className="tooltip-icon">!</span>Character limit reached
    </Tooltip>
  );

  useEffect(() => {
    if (playerData.package) {
      checkPackageCustomizations(playerData.package.name);
    }
    checkFormValidation();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <Form
        id="mainForm"
        noValidate
        validated={validationChecked}
        onSubmit={handleSubmit}
      >
        <Card border="secondary" className="ringSelectionCard">
          <Card.Header bg="secondary" text="white" className="cardHeader">
            {individualSelection === true && (
              <Form.Group md="4">
                <Form.Label>Team Member Name</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    required
                    type="text"
                    name="studentName"
                    placeholder="Name"
                    defaultValue={playerData.studentName}
                    onChange={(e) =>
                      handleInputChange('studentName', e.target.value)
                    }
                    className="headerInput"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter team member name.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            )}

            {roster.accessLevel === 'Coach' && individualSelection === true && (
              <>
                <Form.Group md="4">
                  <Form.Label>Team Member Email (Optional)</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="email"
                      name="studentEmail"
                      placeholder="Email"
                      defaultValue={playerData.studentEmail}
                      onChange={(e) =>
                        handleInputChange('studentEmail', e.target.value)
                      }
                      className="headerInput"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group md="4">
                  <Form.Label>Team Member Phone (Optional)</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="tel"
                      pattern="(\d|[()-]|\s)+"
                      name="studentPhone"
                      id="studentPhone"
                      placeholder="Phone Number"
                      defaultValue={playerData.studentPhone}
                      onChange={(e) =>
                        handleInputChange('studentPhone', e.target.value)
                      }
                      className="headerInput"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid phone number.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </>
            )}
            {roster.accessLevel === 'Student' && (
              <>
                <Form.Group md="4">
                  <Form.Label>Team Member Email (Optional)</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="email"
                      name="studentEmail"
                      placeholder="Email"
                      defaultValue={playerData.studentEmail}
                      onChange={(e) =>
                        handleInputChange('studentEmail', e.target.value)
                      }
                      className="headerInput"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group md="4">
                  <Form.Label>Team Member Phone</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="tel"
                      pattern="(\d|[()-]|\s)+"
                      name="studentPhone"
                      id="studentPhone"
                      placeholder="Phone Number"
                      defaultValue={playerData.studentPhone}
                      onChange={(e) =>
                        handleInputChange('studentPhone', e.target.value)
                      }
                      className="headerInput"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid phone number.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </>
            )}

            {roster.shippingTypeName == 'Individual Shipping' && (
              <>
                <Form.Group md="4">
                  <Form.Label>Country/Region</Form.Label>
                  <InputGroup required hasValidation>
                    <Form.Control
                      required={
                        roster.shippingTypeName === 'Individual Shipping'
                      }
                      as="select"
                      name="countryCode"
                      id="countryCode"
                      defaultValue={'US'}
                      onChange={(e) =>
                        handleInputChange('countryCode', e.target.value)
                      }
                      value={formValues.countryCode}
                    >
                      <option value="">Please make a selection</option>
                      {countries.map((item, idx) => (
                        <option key={idx} value={item.countryCode}>
                          {item.countryCode} - {item.name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Required.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group md="4">
                  <Form.Label>Address Line 1</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      required={
                        roster.shippingTypeName === 'Individual Shipping'
                      }
                      type="text"
                      name="addressLine1"
                      placeholder="Address Line 1"
                      defaultValue={playerData.addressLine1}
                      onChange={(e) =>
                        handleInputChange('addressLine1', e.target.value)
                      }
                      className="headerInput"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter Address Line 1.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group md="4">
                  <Form.Label>Address Line 2 (Optional)</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      name="addressLine2"
                      placeholder="Address Line 2"
                      defaultValue={playerData.addressLine2}
                      onChange={(e) =>
                        handleInputChange('addressLine2', e.target.value)
                      }
                      className="headerInput"
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group md="4">
                  <Form.Label>City</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      required={
                        roster.shippingTypeName === 'Individual Shipping'
                      }
                      type="text"
                      name="city"
                      placeholder="City"
                      defaultValue={playerData.city}
                      onChange={(e) =>
                        handleInputChange('city', e.target.value)
                      }
                      className="headerInput"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter City.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group md="4">
                  <Form.Label>State</Form.Label>
                  <InputGroup required hasValidation>
                    <Form.Control
                      required={
                        roster.shippingTypeName === 'Individual Shipping'
                      }
                      as="select"
                      name="state"
                      id="state"
                      defaultValue={playerData.state ? playerData.state : ''}
                      onChange={(e) =>
                        handleInputChange('state', e.target.value)
                      }
                      value={formValues.state}
                    >
                      <option value="">Please make a selection</option>
                      {states.map((item, idx) => (
                        <option key={idx} value={item.regionCode}>
                          {item.regionCode} - {item.name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Required.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group md="4">
                  <Form.Label>Postal Code</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      required={
                        roster.shippingTypeName === 'Individual Shipping'
                      }
                      type="text"
                      name="postalCode"
                      placeholder="Postal Code"
                      defaultValue={playerData.postalCode}
                      onChange={(e) =>
                        handleInputChange('postalCode', e.target.value)
                      }
                      className="headerInput"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter Postal Code.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </>
            )}
          </Card.Header>
          <Card.Body className="cardBody">
            <Form.Group>
              <Form.Row>
                <Col>
                  <Form.Label>Product</Form.Label>
                  {console.log('Length: ' + productList.length)}
                  {productList.length > 2 ? (
                    individualSelection === true ||
                    roster.accessLevel === 'Student' ? (
                      <>
                        <Form.Control
                          required
                          as="select"
                          onChange={(e) => {
                            handleInputChange(
                              'masterOrderItemId',
                              e.target.value,
                              'customizations',
                              [],
                              'package',
                              null
                            );

                            setItemId(e.target.value);

                            return setSelectedProduct(e.target.value);
                          }}
                          name="masterOrderItemId"
                          value={itemId}
                          custom
                        >
                          {productList.map((product, idx) => (
                            <option key={idx} value={product.masterOrderItemId}>
                              {product.title}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please select a product.
                        </Form.Control.Feedback>
                      </>
                    ) : (
                      <>
                        <Form.Control
                          required
                          as="select"
                          onChange={(e) => {
                            handleBatchInputChange(
                              selectedPlayers,
                              'masterOrderItemId',
                              e.target.value
                            );

                            setItemId(e.target.value);

                            return setSelectedProduct(e.target.value);
                          }}
                          name="masterOrderItemId"
                          value={itemId}
                          custom
                        >
                          {productList.map((product, idx) => (
                            <option key={idx} value={product.masterOrderItemId}>
                              {product.title}
                            </option>
                          ))}
                        </Form.Control>
                      </>
                    )
                  ) : (
                    <>
                      <div>{productList[1]?.title}</div>
                      <input
                        type="hidden"
                        value={productList[1]?.masterOrderItemId}
                      />
                    </>
                  )}
                </Col>
              </Form.Row>
              <Form.Row>
                {selectedProduct && product && (
                  <RingInputSection
                    handleInputChange={(inputName, value) =>
                      handleInputChange(inputName, value)
                    }
                    handleCustomInputChange={(inputName, value) =>
                      handleCustomInputChange(inputName, value)
                    }
                    handleCustomInputInsideEngravingChange={(
                      inputNameList,
                      value
                    ) =>
                      handleCustomInputInsideEngravingChange(
                        inputNameList,
                        value
                      )
                    }
                    editFormValues={playerData}
                    product={product}
                    individualSelection={individualSelection}
                    view={roster.accessLevel}
                    internalView={internalView}
                    adminUser={adminUser}
                  />
                )}
              </Form.Row>
              {packageProductType && roster.packages.length > 0 && (
                <Form.Row>
                  <div className="spacerDivWithLine" />
                  <Col>
                    <Row>
                      <Col>
                        <h4>Package</h4>
                      </Col>
                      <Col style={{ textAlign: 'right' }}>
                        <a
                          style={{
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setShowPackageModal(true);
                            document.getElementById(
                              'mainModal'
                            ).style.visibility = 'hidden';
                          }}
                        >
                          What's Included?
                        </a>
                      </Col>
                    </Row>
                    <div className="mb-3 radio-toolbar" id="packageRadios">
                      {championsPackageAvailable && (
                        <Row>
                          <Col>
                            <Form.Check required type="radio">
                              <Form.Check.Input
                                required
                                type="radio"
                                value="Champions"
                                id="Champions"
                                name="package"
                                onChange={(e) => {
                                  handleInputChange('package', {
                                    name: e.target.value,
                                    customizations: null,
                                  });
                                  checkPackageCustomizations(e.target.value);
                                }}
                                defaultChecked={
                                  (roster.accessLevel === 'Coach' &&
                                    playerData.package !== null &&
                                    playerData.package.name === 'Champions') ||
                                  formValues.package === 'Champions'
                                }
                              />
                              <Form.Check.Label htmlFor="Champions">
                                <Row>
                                  <Col>
                                    <Image
                                      fluid
                                      src={championsPackageImage}
                                      alt="Champions"
                                    />
                                  </Col>
                                  <Col style={{ textAlign: 'right' }}>
                                    Champions
                                    <br />
                                    <span style={{ color: 'red' }}>
                                    {roster.packages.filter((ringPackage) => ringPackage.title.includes('Champions') && !ringPackage.title.includes('Champions+')).length === 1 && 
                                      roster.packages.find((ringPackage) => ringPackage.title.includes('Champions') && !ringPackage.title.includes('Champions+')).price === 0 ? 'FREE!'
                                      : `+$${
                                          roster.packages
                                            .filter(
                                              (ringPackage) =>
                                                ringPackage.title.includes('Champions') &&
                                                !ringPackage.title.includes('Champions+')
                                            )
                                            .reduce((max, pkg) => (pkg.price > max ? pkg.price : max), 0)
                                        }`
                                    }
                                    </span>
                                    <br />
                                    <span style={{ color: '#b12928' }}>
                                      <small>Most Popular</small>
                                    </span>
                                  </Col>
                                </Row>
                              </Form.Check.Label>
                              <Form.Control.Feedback type="invalid">
                                Please select a package.
                              </Form.Control.Feedback>
                            </Form.Check>
                          </Col>
                        </Row>
                      )}
                      {championsPlusPackageAvailable && (
                        <Row>
                          <Col>
                            <Form.Check required type="radio">
                              <Form.Check.Input
                                required
                                type="radio"
                                value="Champions+"
                                id="Champions+"
                                name="package"
                                onChange={(e) => {
                                  handleInputChange('package', {
                                    name: e.target.value,
                                    customizations: [
                                      {
                                        name: 'First Name',
                                        text: null,
                                      },
                                      {
                                        name: 'Last Name',
                                        text: null,
                                      },
                                    ],
                                  });
                                  checkPackageCustomizations(e.target.value);
                                }}
                                onLoad={() => {
                                  initalEngraving();
                                }}
                                defaultChecked={
                                  (roster.accessLevel === 'Coach' &&
                                    playerData.package !== null &&
                                    playerData.package.name === 'Champions+') ||
                                  formValues.package === 'Champions+'
                                }
                              />
                              <Form.Check.Label htmlFor="Champions+">
                                <Row>
                                  <Col>
                                    <Image
                                      fluid
                                      src={championsPlusPackageImage}
                                      alt="Champions+"
                                    />
                                  </Col>
                                  <Col style={{ textAlign: 'right' }}>
                                    Champions+
                                    <br />
                                    <span style={{ color: 'red' }}>
                                    {roster.packages.filter((ringPackage) => ringPackage.title.includes('Champions+')).length === 1 && 
                                      roster.packages.find((ringPackage) => ringPackage.title.includes('Champions+')).price === 0 ? 'FREE!'
                                      : `+$${
                                          roster.packages
                                            .filter(
                                              (ringPackage) =>
                                                ringPackage.title.includes('Champions+')
                                            )
                                            .reduce((max, pkg) => (pkg.price > max ? pkg.price : max), 0)
                                        }`
                                    }
                                    </span>
                                    <br />
                                    <span style={{ color: '#b12928' }}>
                                      <small>New!</small>
                                    </span>
                                  </Col>
                                </Row>
                              </Form.Check.Label>
                              <Form.Control.Feedback type="invalid">
                                Please select a package.
                              </Form.Control.Feedback>
                            </Form.Check>
                          </Col>
                        </Row>
                      )}
                      {dynastyPackageAvailable && (
                        <Row>
                          <Col>
                            <Form.Check required type="radio">
                              <Form.Check.Input
                                required
                                type="radio"
                                value="Dynasty"
                                id="Dynasty"
                                name="package"
                                onChange={(e) => {
                                  handleInputChange('package', {
                                    name: e.target.value,
                                    customizations: [
                                      {
                                        name: 'First Name',
                                        text: null,
                                      },
                                      {
                                        name: 'Last Name',
                                        text: null,
                                      },
                                    ],
                                  });
                                  checkPackageCustomizations(e.target.value);
                                }}
                                onLoad={() => {
                                  initalEngraving();
                                }}
                                defaultChecked={
                                  (roster.accessLevel === 'Coach' &&
                                    playerData.package !== null &&
                                    playerData.package.name === 'Dynasty') ||
                                  formValues.package === 'Dynasty'
                                }
                              />
                              <Form.Check.Label htmlFor="Dynasty">
                                <Row>
                                  <Col>
                                    <Image
                                      fluid
                                      src={dynastyPackageImage}
                                      alt="Dynasty"
                                    />
                                  </Col>
                                  <Col style={{ textAlign: 'right' }}>
                                    Dynasty
                                    <br />
                                    <span style={{ color: 'red' }}>
                                    {roster.packages.filter((ringPackage) => ringPackage.title.includes('Dynasty')).length === 1 && 
                                      roster.packages.find((ringPackage) => ringPackage.title.includes('Dynasty')).price === 0 ? 'FREE!'
                                      : `+$${
                                          roster.packages
                                            .filter(
                                              (ringPackage) =>
                                                ringPackage.title.includes('Dynasty')
                                            )
                                            .reduce((max, pkg) => (pkg.price > max ? pkg.price : max), 0)
                                        }`
                                    }
                                    </span>
                                    <br />
                                    <span style={{ color: '#b12928' }}>
                                      <small>Coach's Choice</small>
                                    </span>
                                  </Col>
                                </Row>
                              </Form.Check.Label>
                              <Form.Control.Feedback type="invalid">
                                Please select a package.
                              </Form.Control.Feedback>
                            </Form.Check>
                          </Col>
                        </Row>
                      )}
                      {ultimatePackageAvailable && (
                        <Row>
                          <Col>
                            <Form.Check required type="radio">
                              <Form.Check.Input
                                required
                                type="radio"
                                value="Ultimate"
                                id="Ultimate"
                                name="package"
                                onChange={(e) => {
                                  handleInputChange('package', {
                                    name: e.target.value,
                                    customizations: [
                                      {
                                        name: 'First Name',
                                        text: null,
                                      },
                                      {
                                        name: 'Last Name',
                                        text: null,
                                      },
                                      {
                                        name: 'T-Shirt Size',
                                        text: null,
                                      },
                                      {
                                        name: 'Jersey Number Pendant',
                                        text: null,
                                      },
                                    ],
                                  });
                                  checkPackageCustomizations(e.target.value);
                                }}
                                onLoad={() => {
                                  initalEngraving();
                                }}
                                defaultChecked={
                                  (roster.accessLevel === 'Coach' &&
                                    playerData.package !== null &&
                                    playerData.package.name === 'Ultimate') ||
                                  formValues.package === 'Ultimate'
                                }
                              />
                              <Form.Check.Label htmlFor="Ultimate">
                                <Row>
                                  <Col>
                                    <Image
                                      fluid
                                      src={ultimatePackageImage}
                                      alt="Ultimate"
                                    />
                                  </Col>
                                  <Col style={{ textAlign: 'right' }}>
                                    Ultimate
                                    <br />
                                    <span style={{ color: 'red' }}>
                                    {roster.packages.filter((ringPackage) => ringPackage.title.includes('Ultimate')).length === 1 && 
                                      roster.packages.find((ringPackage) => ringPackage.title.includes('Ultimate')).price === 0 ? 'FREE!'
                                      : `+$${
                                          roster.packages
                                            .filter(
                                              (ringPackage) =>
                                                ringPackage.title.includes('Ultimate')
                                            )
                                            .reduce((max, pkg) => (pkg.price > max ? pkg.price : max), 0)
                                        }`
                                    }
                                    </span>
                                    <br />
                                    <span style={{ color: '#b12928' }}>
                                      <small>Biggest Savings</small>
                                    </span>
                                  </Col>
                                </Row>
                              </Form.Check.Label>
                              <Form.Control.Feedback type="invalid">
                                Please select a package.
                              </Form.Control.Feedback>
                            </Form.Check>
                          </Col>
                        </Row>
                      )}
                      {basicPackageAvailable && (
                        <Form.Check required type="radio">
                          <Form.Check.Input
                            required
                            type="radio"
                            value="Basic"
                            id="Basic"
                            name="package"
                            onChange={(e) => {
                              handleInputChange('package', {
                                name: e.target.value,
                                customizations: null,
                              });
                              checkPackageCustomizations(e.target.value);
                            }}
                            defaultChecked={
                              (roster.accessLevel === 'Coach' &&
                                playerData.package !== null &&
                                playerData.package.name === 'Basic') ||
                              formValues.package === 'Basic'
                            }
                          />
                          <Form.Check.Label
                            className="mt-2"
                            id="basicBtn"
                            htmlFor="Basic"
                          >
                            <Row>
                              <Col>
                                <Image
                                  style={{ height: '30px' }}
                                  fluid
                                  src={basicPackageImage}
                                  alt="Basic"
                                />
                              </Col>
                              <Col style={{ textAlign: 'right' }}>
                                Basic
                                <br />
                                <span style={{ color: 'red' }}>
                                {roster.packages.filter((ringPackage) => ringPackage.title.includes('Basic')).length === 1 && 
                                roster.packages.find((ringPackage) => ringPackage.title.includes('Basic')).price === 0 ? 'FREE!'
                                  : `+$${
                                      roster.packages
                                        .filter(
                                          (ringPackage) =>
                                            ringPackage.title.includes('Basic')
                                        )
                                        .reduce((max, pkg) => (pkg.price > max ? pkg.price : max), 0)
                                    }`
                                }
                                </span>
                              </Col>
                            </Row>
                          </Form.Check.Label>
                          <Form.Control.Feedback type="invalid">
                            Please select a package.
                          </Form.Control.Feedback>
                        </Form.Check>
                      )}
                    </div>
                  </Col>
                </Form.Row>
              )}
              {packageHasNameplate && individualSelection && (
                <Form.Row>
                  <div className="spacerDivWithLine" />

                  <Col className="column1">
                    <h5>Nameplate Engraving</h5>

                    <Image
                      fluid
                      src={selectedNameplateImage}
                      alt="nameplate"
                      className="nameplateMobile"
                    />
                    {isShowMaxLength ? (
                      <Form.Group md="4">
                        <Form.Label>First Name</Form.Label>
                        <InputGroup required hasValidation>
                          <OverlayTrigger
                            trigger={['click']}
                            placement="bottom"
                            overlay={renderTooltip()}
                            show={showTooltip && valueFirstName.length >= 25}
                          >
                            <Form.Control
                              required={true}
                              type="text"
                              name="nameplateFirstName"
                              id="nameplateFirstName"
                              placeholder="Max 25 Characters"
                              maxLength="25"
                              defaultValue={
                                playerData.package &&
                                'package' in playerData &&
                                playerData.package.customizations &&
                                'customizations' in playerData.package &&
                                playerData.package.customizations.length > 0
                                  ? playerData.package.customizations[0].text
                                  : ''
                              }
                              onChange={(e) => {
                                handlePackageInputChange(
                                  e.target.name,
                                  e.target.value
                                );
                                setValueFirstName(e.target.value);
                                if (e.target.value.length >= 25)
                                  setShowTooltip(true);
                                setTimeout(() => {
                                  setShowTooltip(false);
                                }, 2000);
                              }}
                              onInput={(e) => handlePackageValidation()}
                              className="headerInput"
                              style={{ textTransform: 'uppercase' }}
                              ref={inputRef}
                              onClick={() =>
                                handleInputClick(valueFirstName, 'First Name')
                              }
                            />
                          </OverlayTrigger>
                          <Form.Control.Feedback type="invalid">
                            Required.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    ) : (
                      <Form.Group md="4">
                        <Form.Label>First Name</Form.Label>
                        <InputGroup required hasValidation>
                          <Form.Control
                            required={true}
                            type="text"
                            name="nameplateFirstName"
                            id="nameplateFirstName"
                            defaultValue={
                              playerData.package &&
                              'package' in playerData &&
                              playerData.package.customizations &&
                              'customizations' in playerData.package &&
                              playerData.package.customizations.length > 0
                                ? playerData.package.customizations[0].text
                                : ''
                            }
                            onChange={(e) => {
                              handlePackageInputChange(
                                e.target.name,
                                e.target.value
                              );
                              setValueFirstName(e.target.value);
                              if (e.target.value.length >= 25)
                                setShowTooltip(true);
                              setTimeout(() => {
                                setShowTooltip(false);
                              }, 2000);
                            }}
                            onInput={(e) => handlePackageValidation()}
                            className="headerInput"
                            style={{ textTransform: 'uppercase' }}
                            ref={inputRef}
                            onClick={() =>
                              handleInputClick(valueFirstName, 'First Name')
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Required.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    )}
                    {isShowMaxLength ? (
                      <Form.Group md="4">
                        <Form.Label>Last Name</Form.Label>
                        <InputGroup hasValidation>
                          <OverlayTrigger
                            trigger={['click']}
                            placement="bottom"
                            overlay={renderTooltip()}
                            show={
                              showTooltipLastName && valueLastName.length >= 25
                            }
                          >
                            <Form.Control
                              required={true}
                              type="text"
                              name="nameplateLastName"
                              id="nameplateLastName"
                              placeholder="Max 25 Characters"
                              maxLength="25"
                              defaultValue={
                                playerData.package &&
                                'package' in playerData &&
                                playerData.package.customizations &&
                                'customizations' in playerData.package &&
                                playerData.package.customizations.length > 0
                                  ? playerData.package.customizations[1].text
                                  : ''
                              }
                              onChange={(e) => {
                                handlePackageInputChange(
                                  e.target.name,
                                  e.target.value
                                );
                                setValueLastName(e.target.value);
                                if (e.target.value.length >= 25) {
                                  setShowTooltipLastName(true);
                                  setTimeout(() => {
                                    setShowTooltipLastName(false);
                                  }, 2000);
                                }
                              }}
                              onInput={(e) => handlePackageValidation()}
                              className="headerInput"
                              style={{ textTransform: 'uppercase' }}
                              ref={inputRef}
                              onClick={() =>
                                handleInputClick(valueLastName, 'Last Name')
                              }
                            />
                          </OverlayTrigger>
                          <Form.Control.Feedback type="invalid">
                            Required.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    ) : (
                      <Form.Group md="4">
                        <Form.Label>Last Name</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            required={true}
                            type="text"
                            name="nameplateLastName"
                            id="nameplateLastName"
                            defaultValue={
                              playerData.package &&
                              'package' in playerData &&
                              playerData.package.customizations &&
                              'customizations' in playerData.package &&
                              playerData.package.customizations.length > 0
                                ? playerData.package.customizations[1].text
                                : ''
                            }
                            onChange={(e) => {
                              handlePackageInputChange(
                                e.target.name,
                                e.target.value
                              );
                              setValueLastName(e.target.value);
                              if (e.target.value.length >= 25) {
                                setShowTooltipLastName(true);
                                setTimeout(() => {
                                  setShowTooltipLastName(false);
                                }, 2000);
                              }
                            }}
                            onInput={(e) => handlePackageValidation()}
                            className="headerInput"
                            style={{ textTransform: 'uppercase' }}
                            ref={inputRef}
                            onClick={() =>
                              handleInputClick(valueLastName, 'Last Name')
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Required.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    )}
                  </Col>
                  <Col className="column2">
                    <Image
                      fluid
                      src={selectedNameplateImage}
                      alt="nameplate"
                      className="nameplate"
                    />
                  </Col>
                </Form.Row>
              )}
              {packageHasAccessories && individualSelection && (
                <>
                  <Form.Row>
                    <div className="spacerDivWithLine" />
                    <Col>
                      <Form.Group md="4">
                        <Form.Label>T-Shirt Size</Form.Label>
                        <InputGroup required hasValidation>
                          <Form.Control
                            required={true}
                            as="select"
                            name="tShirtSize"
                            id="tShirtSize"
                            defaultValue={
                              playerData.package &&
                              playerData.package.customizations &&
                              playerData.package.customizations[2]
                                ? playerData.package.customizations[2].text
                                : ''
                            }
                            onChange={(e) =>
                              handlePackageInputChange(
                                e.target.name,
                                e.target.value
                              )
                            }
                            onInput={(e) => handlePackageValidation()}
                          >
                            <option disabled value="">
                              Choose t-shirt size
                            </option>
                            <option value="S">S</option>
                            <option value="M">M</option>
                            <option value="L">L</option>
                            <option value="XL">XL</option>
                            <option value="2XL">2XL</option>
                            <option value="3XL">3XL</option>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Required.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <div className="spacerDivWithLine" />
                    <Col>
                      <Form.Group md="4">
                        <Form.Label>Jersey Number Pendant</Form.Label>
                        <InputGroup required hasValidation>
                          <Form.Control
                            required={true}
                            as="select"
                            name="jerseyNumberPendant"
                            id="jerseyNumberPendant"
                            defaultValue={
                              playerData.package &&
                              playerData.package.customizations &&
                              playerData.package.customizations[3]
                                ? playerData.package.customizations[3].text
                                : ''
                            }
                            onChange={(e) =>
                              handlePackageInputChange(
                                e.target.name,
                                e.target.value
                              )
                            }
                            onInput={(e) => handlePackageValidation()}
                          >
                            <option disabled value="">
                              Choose jersey number
                            </option>
                            {jerseyNumberPendantOptions.map((number) => (
                              <option key={number} value={number}>
                                {number}
                              </option>
                            ))}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Required.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </>
              )}
            </Form.Group>
            {!validForm && (
              <div className="alert alert-danger">
                Some fields are missing or incorrectly filled in. Please review
                all fields before submitting.
              </div>
            )}
            <ButtonGroup>
              {editMode && individualSelection === true && (
                <>
                  <Button
                    type="submit"
                    onClick={() => {
                      onUpdateClick(formValues);
                    }}
                    className="m-2"
                    id="update"
                  >
                    <FontAwesomeIcon icon={faEdit} /> Update
                  </Button>
                </>
              )}

              {editMode && individualSelection === false && (
                <>
                  <Button
                    type="submit"
                    onClick={() => {
                      onUpdatesClick(
                        selectedPlayers,
                        formValues.variationId,
                        formValues.variation,
                        formValues.package
                      );
                    }}
                    className="m-2"
                    id="update"
                  >
                    <FontAwesomeIcon icon={faEdit} /> Update
                  </Button>
                </>
              )}

              {!editMode && (
                <>
                  <Button
                    type="submit"
                    variant="success"
                    onClick={() => onSubmitClick(formValues)}
                    className="m-2"
                    id="add"
                  >
                    <FontAwesomeIcon icon={faSave} /> Submit
                  </Button>
                </>
              )}

              <Button
                variant="secondary"
                onClick={() => onCancelClick()}
                className="m-2"
              >
                <FontAwesomeIcon icon={faWindowClose} /> Cancel
              </Button>
            </ButtonGroup>
          </Card.Body>
        </Card>
      </Form>
      <Modal animation={false} show={showAddModal}>
        <Modal.Header closeButton>
          <Modal.Title>{working ? 'Adding...' : 'Order Added'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {working && (
            <div className="text-center">
              <Spinner animation="border" />
            </div>
          )}
          {!working && <>Your order has been received.</>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => setShowAddModal(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        className="chartModal"
        animation={false}
        show={showPackageModal}
        onHide={() => {
          setShowPackageModal(false);
          document.getElementById('mainModal').style.visibility = 'visible';
        }}
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Package Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PackageComparisonTable />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowPackageModal(false);
              document.getElementById('mainModal').style.visibility = 'visible';
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RingSelectionView;
