import React, { useState, useEffect, useContext } from 'react';
import { Form, Image, Col } from 'react-bootstrap';
import InputGenerator from '../InputGenerator/InputGenerator';
import './RingInputSection.css';
import placeholderImage from '../../assets/images/Ring-Placeholder.png';
import { UserContext } from '../../contexts/UserContext';

const RingInputSection = ({
  product,
  handleInputChange,
  handleCustomInputChange,
  handleCustomInputInsideEngravingChange,
  editFormValues,
  individualSelection,
  view,
  internalView,
  adminUser,
}) => {
  const roleHiddenMaxLength = ['SalesManager', 'Verifier'];
  const { user } = useContext(UserContext);
  const [imgHref, setImgHref] = useState('');
  const [variationId, setVariationId] = useState('');
  const [fingersSize, setFingersSize] = useState('');
  const [selectedOptionalEngraving, setSelectedOptionalEngraving] = useState(
    editFormValues.optionalEngraving
  );
  const [optionalEngraving, setOptionalEngraving] = useState();
  const [oneVariant, setOneVariant] = useState(false);
  const [isShowMaxLength, setIsShowMaxLength] = useState(true);
  
  const handleOptionChange = (e) => {
    setSelectedOptionalEngraving(e.target.value === 'true');
    if (e.target.value === 'false') {
      handleCustomInputInsideEngravingChange(
        [`Engraving Line 1`, `Engraving Line 2`, `Engraving Line 3`],
        ''
      );
      handleInputChange('optionalEngraving', false);
    } else {
      handleInputChange('optionalEngraving', true);
    }
  };

  const handleSelectChange = (e) => {
    if (e.target.name === 'variationId') {
      setVariationId(e.target.value);
    }

    if (e.target.name === 'fingerSize') {
      setFingersSize(e.target.value);
    }

    handleInputChange(e.target.name, e.target.value);
  };
  
  useEffect(() =>{
    product.customizations.forEach((item) => {
      if (product !== null && (((product?.productType === "Championship Ring" || product?.productType === "Fashion Ring") && item.name !== "Engraving Line 1" && item.name !== "Engraving Line 2" && item.name !== "Engraving Line 3" && item.name !== "Engraving") || (product?.productType !=="Championship Ring" && product?.productType !== "Fashion Ring"))) {
        setOptionalEngraving(false)
      } else { 
        setOptionalEngraving(true)
      }})
      
    if (user != null) {
      const isHidden = roleHiddenMaxLength.some(r => user.roles.includes(r));
      setIsShowMaxLength(!isHidden);
    }
  }, []);

  useEffect(() => {
    const newHref = product.variations.filter(
      (variationObject) => variationObject.variationId === variationId
    );
    if (newHref.length > 0) {
      setImgHref(newHref[0].artworkUrl);
    } else {
      setImgHref('');
    }
  }, [variationId]);
  useEffect(() => {
    if(product.variations && oneVariant === false){
      if(product.variations.length === 1)
      {
        setVariationId(product.variations[0].variationId);
        handleInputChange("variationId", product.variations[0].variationId);
        
      }
      setOneVariant(true);
    }
  })
  useEffect(() => {
    if (editFormValues) {
      setVariationId(editFormValues.variationId);
    }
  }, [editFormValues]);

  useEffect(() => {
    if (editFormValues) {
      setFingersSize(editFormValues.fingerSize);
    }
  }, [editFormValues]);

  const filterInputValue = (inputName) => {
    if (editFormValues) {
      const custObject = editFormValues.customizations.filter(
        (customization) =>
          customization.name.toLowerCase() === inputName.toLowerCase()
      );
      if (custObject.length > 0) {
        return custObject[0].text;
      }
    }
    return '';
  };

  const [fingerSizes, setFingerSizes] = useState([
    5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13,
    13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18,
  ]);

  useEffect(() =>{
    
    if(adminUser)
    {
      setFingerSizes([3, 3.25, 3.5, 3.75, 4, 4.25, 4.5, 4.75, 5, 5.25, 5.5, 5.75, 6, 6.25, 6.5, 6.75, 7, 7.25, 7.5, 7.75, 8, 8.25, 8.5, 8.75, 9, 9.25, 9.5, 9.75, 10, 10.25, 10.5, 10.75, 11, 11.25, 11.5, 11.75, 12, 12.25, 12.5, 12.75, 13,
        13.25, 13.5, 13.75, 14, 14.25, 14.5, 14.75, 15, 15.25, 15.5, 15.75, 16, 16.25, 16.5, 16.75, 17, 17.25, 17.5, 17.75, 18, 18.25, 18.5, 18.75, 19, 19.25, 19.5, 19.75, 20, 20.25, 20.5, 20.75, 21, 21.25,
        21.5, 21.75, 22, 22.25, 22.5, 22.75, 23, 23.25, 23.5, 23.75, 24, 24.25, 24.5, 24.75, 25])
    }
  }, [editFormValues]);

  return (
    <>
      {product.variations && product.variations.length > 0 && (
        <div className="spacerDivWithLine" />
      )}
      {product.variations && product.variations.length > 0 && (
        <Col>
          <Form.Group>
            <Form.Label>Metal/Size</Form.Label>
            {product.variations.length > 1 ?(
              <>
            <Form.Control
              required
              as="select"
              id="variationId"
              name="variationId"
              value={variationId}
              custom
              onChange={(e) => handleSelectChange(e)}
            >
              <option value="">{`Select - Metal / Size`}</option>
              {product.variations.map((productVariation, idx) => (
                <option key={idx} value={productVariation.variationId}>
                  {view === 'Student'
                    ? productVariation.description.replace(/ for .*/, '')
                    : productVariation.description}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select the metal and size.
            </Form.Control.Feedback>
            <div className="spacerDivWithLine" />{
      
          imgHref !== null && imgHref !== "" ? (
          imgHref !== 'https://drive.google.com/uc?export=view&id=' && (
        <Image className="artworkImg" fluid src={imgHref}></Image>
      )) : (
        <>

        <Image className='artworking' fluid src={placeholderImage}></Image>
        </>
      )} </>):(
              <><div>{view === 'Student' ? product.variations[0]?.description.replace(/ for .*/, '') : product.variations[0]?.description}</div>
            <Form.Control
              as="hidden"
              name="variationId"
              value={variationId}
              custom></Form.Control>
               <div className="spacerDivWithLine" />
              {product.variations[0]?.artworkUrl !== null ? (
                <Image className="artworkImg" fluid src={product.variations[0].artworkUrl}></Image>):( <Image className='artworking' fluid src={placeholderImage}></Image>)
              }
            {//<input id = 'variationId' name = 'variationId' type = 'hidden' value = {variationId}/>
}
            </>)}
            </Form.Group>
            {(product.productType === 'Championship Ring' ||
              product.productType === 'Fashion Ring') &&
              individualSelection === true && (
                <>
                  <div className="spacerDivWithLine" />
                  <Form.Group>
                  <Form.Label>Finger Size</Form.Label>

                  <Form.Control
                    required
                    as="select"
                    id="fingerSize"
                    name="fingerSize"
                    value={fingersSize}
                    custom
                    onChange={(e) => handleSelectChange(e)}
                  >
                    <option value="">{`Select - Finger Size`}</option>
                    {fingerSizes.map((fingerSize, idx) => (
                      <option key={idx} value={fingerSize}>
                        {fingerSize}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select your finger size.
                  </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
          
        </Col>
      )}

      {product.customizations &&
        product.customizations.length > 0 &&
        individualSelection === true && (
          <>
            <div className="spacerDivWithLine" />
            <Col>
              <h4>Customizations</h4>
              {product.customizations.map(
                (input) =>
                  (((product.productType === 'Championship Ring' || product.productType === 'Fashion Ring') &&
                    input.name !== 'Engraving Line 1' &&
                    input.name !== 'Engraving Line 2' &&
                    input.name !== 'Engraving Line 3' &&
                    input.name !== 'Engraving') ||
                    (product.productType !== 'Championship Ring' && product.productType != 'Fashion Ring')) && (
                    <InputGenerator
                      key={input.name}
                      label={input.name}
                      maxLength={isShowMaxLength ? (input.maxLength ? input.maxLength : 0) : undefined}
                      handleInputChange={(inputName, value) =>
                        handleCustomInputChange(inputName, value)
                      }
                      inputValue={filterInputValue(input.name)}
                    />
                  )
              )}
              
              {product.productType.includes("Ring") && optionalEngraving && (
                <Form.Group>
                  <Form.Label>
                    Inside Ring Engraving{' '}
                    <small className="text-danger">
                      {
                         product.disableEngravingUpcharge === true ? <span>FREE!</span> : <span>($10 w/ Basic package or FREE w/ any package upgrade)</span>
                      }
                    </small>
                  </Form.Label>
                  <div className="optionEngraving">
                    <Form.Check required type="radio">
                      <Form.Check.Input
                        type="radio"
                        label="Yes"
                        name="optionalEngraving"
                        id="radio-yes"
                        value={true}
                        checked={selectedOptionalEngraving === true}
                        onChange={handleOptionChange}
                        custom
                        required={selectedOptionalEngraving ?? true}
                      />
                      <Form.Check.Label htmlFor="radio-yes">
                        Yes
                      </Form.Check.Label>
                      <Form.Check.Input
                        type="radio"
                        label="No"
                        name="optionalEngraving"
                        id="radio-no"
                        value={false}
                        checked={selectedOptionalEngraving === false}
                        onChange={handleOptionChange}
                        custom
                        required={selectedOptionalEngraving ?? true}
                      />
                      <Form.Check.Label htmlFor="radio-no">No</Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        Please enter the customization value.
                      </Form.Control.Feedback>
                    </Form.Check>
                  </div>
                </Form.Group>
              )}
              {selectedOptionalEngraving &&
                product.customizations.map(
                  (input) =>
                    !(
                      ((product.productType === 'Championship Ring' || product.productType === 'Fashion Ring')&&
                        input.name !== 'Engraving Line 1' &&
                        input.name !== 'Engraving Line 2' &&
                        input.name !== 'Engraving Line 3' &&
                        input.name !== 'Engraving') ||
                      (product.productType !== 'Championship Ring' && product.productType !== 'Fashion Ring')
                    ) && (
                      <InputGenerator
                        key={input.name}
                        label={input.name}
                        maxLength={isShowMaxLength ? (input.maxLength ? input.maxLength : 0) : undefined}
                        handleInputChange={(inputName, value) =>
                          handleCustomInputChange(inputName, value)
                        }
                        inputValue={filterInputValue(input.name)}
                      />
                    )
                )}
            </Col>
          </>
        )}
    </>
  );
};

export default RingInputSection;
