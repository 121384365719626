import axios from 'axios';

const getOrderReviews = async (orderId) => {
    try {
      let url = `https://${process.env.REACT_APP_API_SERVER}/api/CoachReview/Orders/${orderId}`;
      
      const res = await axios.get(url);
      if (res.data) {
        return res.data;
      } else {
        console.error('EMPTY ORDER REVIEWS');
        return [];
      }
    } catch (e) {
      console.error('ERROR RETRIEVING ORDER REVIEWS');
      console.error(e);
      return [];
    }
  };
  
  export default getOrderReviews;
  