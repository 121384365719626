import React, { useState } from 'react';
import { Row, Col, Button, Card, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
const PlayerCardBody = ({
  player,
  editPlayer,
  deleteCard,
  count,
  rosterComplete,
  orderInfo,
  orderApproved,
  individualSelection,
  playerSelectCount,
  setPlayerSelectCount,
  incrementPlayerCount,
  decrementPlayerCount,
  adjustCount,
  selectedPlayers,
  setSelectedPlayers,
  productSelected,
  productTitle,
  productType,
  internalView,
  adminUser,
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const [playerToDelete, setPlayerToDelete] = useState({});

  const handleClose = () => {
    setShowDelete(false);
    setPlayerToDelete({});
  };
  const handleShow = (player) => {
    setShowDelete(true);
    setPlayerToDelete(player);
  };

  const deletePlayer = () => {
    deleteCard(playerToDelete);
    handleClose();
  };

  const rowTitle = productTitle + count;
  const checkboxName = 'playerCheck' + count;

  function adjustPlayerSelectCount(e, player, clickedElement) {
    if (clickedElement === 'checkbox') {
      e = e.target;
    }

    let checkedPlayers = selectedPlayers;
    if (e.checked) {
      playerSelectCount += 1;
      checkedPlayers.push(player);
    } else {
      playerSelectCount -= 1;
      checkedPlayers.map((checkedPlayer, idx) =>
        checkedPlayer.id === player.id
          ? checkedPlayers.splice(idx, 1)
          : checkedPlayers
      );

      selectedPlayers = checkedPlayers;
    }
    setPlayerSelectCount(playerSelectCount);
    return selectedPlayers;
  }

  const bodyCheck = (playerCheckbox, player) => {
    let checkedRow = document.getElementById(rowTitle);
    let rowStyle = checkedRow.style;
    let customerCount = document.getElementsByClassName('NoProduct').length;
    if (rowTitle.includes('NoProduct') && rosterComplete === false) {
      let e = document.getElementById(playerCheckbox);

      if (buttonClick === false) {
        if (!e.checked || e.checked === false) {
          e.checked = true;
          rowStyle.background = '#606666';
          rowStyle.color = 'white';
        } else if (e.checked === true) {
          e.checked = false;
          rowStyle.background = '#f8f9fa';
          rowStyle.color = 'black';
        }
        adjustPlayerSelectCount(e, player, 'row');
      } else {
        setPlayerSelectCount(playerSelectCount);
        buttonClick = false;
      }

      //adjustPlayerSelectCount(e, player, "row");
      if (playerSelectCount === customerCount) {
        document.getElementById('selectAll').disabled = true;
      } else {
        document.getElementById('selectAll').disabled = false;
      }
      if (playerSelectCount <= 0) {
        document.getElementById('unselectAll').disabled = true;
      } else {
        document.getElementById('unselectAll').disabled = false;
      }
    }
  };

  let buttonClick = false;

  return (  
    <>
      <Modal show={showDelete} onHide={handleClose} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Confirm you want to delete {playerToDelete.studentName}'s order.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deletePlayer}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Card bg="light">
        <Card.Body
          onClick={(rowTitle) => bodyCheck(checkboxName, player, rowTitle)}
          id={rowTitle}
          name={rowTitle}
        >
          <Row>
            <Col sm={1}>
              <p>
                {count} &nbsp;
                {!productSelected && (
                  <input
                    type="checkbox"
                    class="checkButtons"
                    id={checkboxName}
                    name={checkboxName}
                    onChange={(e) => adjustPlayerSelectCount(e, player)}
                    hidden
                  />
                )}
              </p>
            </Col>
            <Col sm={4}>
              <Card.Title>{player.studentName}</Card.Title>
              {player.studentEmail !== null && player.studentEmail !== '' && (
                <>
                  <Card.Subtitle>{player.studentEmail}</Card.Subtitle>
                  <br />
                </>
              )}
              {player.studentPhone !== null && player.studentPhone !== '' && (
                <>
                  <Card.Subtitle>{player.studentPhone}</Card.Subtitle>
                  <br />
                </>
              )}

              {orderInfo.shippingTypeName === 'Individual Shipping' &&
              player.addressLine1 ? (
                <small>
                  {player.addressLine1}
                  {player.addressLine2 ? (
                    <>
                      <br /> {player.addressLine2}
                    </>
                  ) : (
                    ''
                  )}
                  <br />
                  {player.city}, {player.state} {player.postalCode}
                  <br />
                  {player.countryCode === null || player.countryCode === ''
                    ? 'US'
                    : player.countryCode}
                </small>
              ) : (
                ''
              )}
            </Col>
            <Col sm={3}>
              {player.customizations && player.customizations.length > 0 && (
                <Card.Subtitle className="mt-2">Customizations</Card.Subtitle>
              )}
              <Card.Text>
                {player.customizations.map((customization, idx) =>
                  customization.text.length > 0 &&
                  (customization.name !== 'Engraving Line 1' ||
                    customization.name !== 'Engraving Line 2' ||
                    customization.name !== 'Engraving Line 3') ? (
                    <span key={idx}>
                      {`${customization.name}: ${customization.text}`.toUpperCase()}
                      <br></br>
                    </span>
                  ) : (
                    player.optionalEngraving == true && (
                      <span key={idx}>
                        {`${customization.name}: ${customization.text}`.toUpperCase()}
                        <br></br>
                      </span>
                    )
                  )
                )}
                {(player.fingerSize != null &&(productType === 'Championship Ring' ||
                  productType === 'Fashion Ring')) && (
                  <>Finger Size: {player.fingerSize}</>
                )}
              </Card.Text>
            </Col>
            <Col sm={3}>
              {player.package !== null && (
                <>
                  <Card.Subtitle className="mt-2">Package</Card.Subtitle>
                  <Card.Text>{player.package.name}</Card.Text>
                  {(player.package.name === 'Dynasty' ||
                    player.package.name === 'Ultimate' ||
                    player.package.name === 'Champions+') &&
                    player.package.customizations && (
                      <small>
                        <br></br>
                        <strong>Package Customizations</strong>
                        {player.package.customizations.map((customization) => (
                          <React.Fragment key={customization.name}>
                            <br></br>
                            {`${customization.name}: ${customization.text}`.toUpperCase()}
                          </React.Fragment>
                        ))}
                      </small>
                    )}
                </>
              )}
            </Col>
            <Col sm={1}>
            {/*Edit condition to include internal view (non-admin) - can't edit if order review approved*/}
              {((player.editable === true && rosterComplete === false) ||
                (internalView && orderApproved === false) || adminUser) && (
                <>
                  <Button
                    onClick={() => {
                      buttonClick = true;
                      individualSelection();
                      editPlayer(player);
                    }}
                    block
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>

                  <Button
                    variant="danger"
                    onClick={() => handleShow(player)}
                    block
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default PlayerCardBody;
