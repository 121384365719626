import PlayerCard from '../../components/PlayerCard/PlayerCard';
import './RosterPage.css';

function RosterPage({ roster, rosterKey, orderInfo, internalView, adminUser }) {
  return (
    <div className="rosterBody">
      {roster && roster.orderId && (
        <div className="contentBody">
          <PlayerCard
            rosterKey={rosterKey}
            roster={roster}
            orderInfo={orderInfo}
            players={roster.lines}
            products={roster.products}
            internalView={internalView}
            adminUser={adminUser}
          >
            Players
          </PlayerCard>
        </div>
      )}
    </div>
  );
}

export default RosterPage;
